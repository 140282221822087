@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url(~rc-tooltip/assets/bootstrap_white.css);
button.btn {
  border: none;
  border-radius: 4px;
  box-shadow: 0px 3px 4px 0px rgba(111, 116, 126, 0.12);
  height: 32px;
  cursor: pointer; }

button.btn--default {
  border-width: 1px;
  border-color: #dfe1e6;
  border-style: solid;
  background-color: white;
  box-shadow: 0px 3px 4px 0px rgba(111, 116, 126, 0.12); }
  button.btn--default:hover {
    background-color: #f1f5f7;
    box-shadow: 0px 12px 26.6px 1.4px rgba(111, 116, 126, 0.22);
    color: #3572b0; }

button.btn--primary {
  background-color: #0052cc;
  color: white; }
  button.btn--primary:hover {
    background-color: #3572b0;
    box-shadow: 0px 12px 26.6px 1.4px rgba(111, 116, 126, 0.22); }

.card {
  border: 1px dashed #cbd6e2;
  border-radius: 12px;
  transition: 0.25s;
  width: 100%; }
  .card__content {
    display: block;
    padding: 25px 18px 20px;
    text-align: center;
    position: relative; }
  .card--color:hover {
    box-shadow: 0px 15px 29.4px 0.6px rgba(var(--app-color), 0.22), 0px 2px 20px 0px rgba(var(--app-color), 0.15);
    background-color: rgba(var(--app-color), 1); }
    .card--color:hover .card__title,
    .card--color:hover .card__description,
    .card--color:hover .card__suffixIcon {
      color: white; }
    .card--color:hover .card__icon {
      background: white; }
    .card--color:hover i[class^="icon-"] {
      color: rgba(var(--app-color), 1); }
  .card--hoverable:hover, .card--checked {
    cursor: pointer;
    border-color: rgba(var(--app-color), 1); }
  .card--hoverable:hover {
    box-shadow: 0px 3px 4px 0px rgba(111, 116, 126, 0.12); }
  .card--color.card--hoverable:hover {
    box-shadow: 0px 15px 29.4px 0.6px rgba(var(--app-color), 0.22), 0px 2px 20px 0px rgba(var(--app-color), 0.15); }
  .card--checked {
    border-style: solid; }
  .card__header {
    position: absolute;
    display: flex;
    align-items: center;
    width: calc(100% - 36px); }
  .card__status {
    position: absolute; }
  .card__suffixIcon {
    position: absolute;
    right: 0px;
    height: 20px;
    cursor: pointer; }
  .card--color .card__suffixIcon {
    color: rgba(var(--app-color), 1); }
  .card--color .card__icon {
    background: rgba(var(--app-color), 1); }
  .card__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
    margin: 15px auto;
    height: 62px;
    width: 62px;
    transition: 0.2s linear; }
  .card__title {
    font-size: 16px;
    color: #333333;
    line-height: 2;
    font-weight: bold;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis; }
  .card__description {
    font-size: 13px;
    color: #42526e;
    line-height: 2;
    text-align: center;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .card__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px; }
  .card__actions > * {
    margin: 0 10px; }
  .card__menu__popover .ant-popover-inner-content {
    padding: 0;
    width: 220px; }
  .card__menu__title {
    padding: 12px 14px;
    color: #42526e;
    font-size: 13px;
    font-weight: 500; }
  .card__menu__items {
    padding-bottom: 5px; }
  .card__menu__item {
    padding: 10px 22px;
    display: flex;
    align-items: center;
    color: #1f325f; }
    .card__menu__item:hover {
      background-color: #f1f5f7;
      cursor: pointer;
      color: #3572b0; }
  .card__menu .item__title {
    font-size: 13px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  .card__menu .item__icon {
    margin-right: 20px;
    font-size: 15px;
    display: flex;
    align-items: center; }
  .card__menu .item__icon svg {
    width: 1em;
    height: 1em; }

.collapse .rc-collapse .rc-collapse-item {
  margin-bottom: 3px; }
  .collapse .rc-collapse .rc-collapse-item .rc-collapse-header {
    background: #fff;
    box-shadow: 0 3px 4px rgba(111, 116, 126, 0.16);
    height: 40px;
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: bold;
    line-height: 40px;
    color: #0052cc;
    padding-left: 16px; }
    .collapse .rc-collapse .rc-collapse-item .rc-collapse-header:focus {
      outline: none; }
    .collapse .rc-collapse .rc-collapse-item .rc-collapse-header .icon-Arrow {
      float: right;
      width: 32px;
      height: 100%;
      display: block;
      position: relative; }
      .collapse .rc-collapse .rc-collapse-item .rc-collapse-header .icon-Arrow svg {
        display: block;
        position: absolute;
        top: calc(50% - 4px);
        left: calc(50% - 6px); }
  .collapse .rc-collapse .rc-collapse-item .panel-header .icon {
    margin-right: 20px;
    display: inline;
    position: relative;
    top: 5px; }
  .collapse .rc-collapse .rc-collapse-item .rc-collapse-content {
    max-height: 145px;
    overflow-y: scroll; }
    .collapse .rc-collapse .rc-collapse-item .rc-collapse-content.rc-collapse-content-active {
      transition: height 0.25s ease-in; }
    .collapse .rc-collapse .rc-collapse-item .rc-collapse-content.rc-collapse-content-inactive {
      transition: height 0.25s ease-in;
      height: 0px; }
    .collapse .rc-collapse .rc-collapse-item .rc-collapse-content::-webkit-scrollbar {
      width: 5px; }
    .collapse .rc-collapse .rc-collapse-item .rc-collapse-content::-webkit-scrollbar-track {
      background: #dfe1e6;
      border: 2px solid #f7fafc; }
    .collapse .rc-collapse .rc-collapse-item .rc-collapse-content::-webkit-scrollbar-thumb {
      background: #7d8798;
      width: 5px;
      border-radius: 3px; }
    .collapse .rc-collapse .rc-collapse-item .rc-collapse-content .rc-collapse-content-box {
      padding: 15px; }

#root .app-fallback {
  min-height: 100vh; }

.app-fallback {
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

.app-fallback .ant-spin-text {
  margin-top: 15px; }

/* Invite Steps */
.invite-steps {
  margin-bottom: 20px;
  position: relative; }

.invite-step .ant-steps-item-title::after {
  display: none; }

.invite-step {
  height: 36px;
  display: flex;
  align-items: center;
  padding: 5px 12px 0 6px;
  border-radius: 19px;
  border: 1px solid #dfe1e6;
  background-color: #ffffff;
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.12);
  flex: 0 1 auto;
  margin-bottom: 10px; }

.invite-step.ant-steps-item-finish {
  background-color: #0052cc; }

.invite-step.ant-steps-item-finish .ant-steps-item-title {
  color: #fff !important; }

.invite-step.ant-steps-item-wait {
  color: #6f747e;
  box-shadow: none; }

.invite-step.ant-steps-item-wait:hover {
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.12); }

.invite-steps.ant-steps-small .ant-steps-item-title {
  font-size: 13px;
  font-weight: 600;
  padding-right: 0;
  color: #333333; }

.invite-step.ant-steps-item-wait:not(.ant-steps-item-active) .ant-steps-item-icon,
.invite-step.ant-steps-item-process:not(.ant-steps-item-active) .ant-steps-item-icon {
  background-color: #f1f5f7;
  border-color: #f1f5f7; }

.invite-step.ant-steps-item-wait:not(.ant-steps-item-active) .ant-steps-item-icon > .ant-steps-icon,
.invite-step.ant-steps-item-process:not(.ant-steps-item-active) .ant-steps-item-icon > .ant-steps-icon {
  color: #333; }

.invite-steps.ant-steps-horizontal.ant-steps-label-horizontal,
.invite-steps.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
  display: block; }

.invite-steps.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: 0; }

/* Wizard title */
.form-wizard__header {
  margin-bottom: 20px; }

.form-wizard__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column; }

.form-wizard__header .page-title {
  margin-bottom: 0; }

.form-wizard__header .page-title + * {
  margin-top: 15px; }

/* Responsive */
@media (min-width: 576px) {
  .form-wizard__header {
    flex-direction: row;
    align-items: center; }
  .form-wizard__header .page-title + * {
    margin-top: 0; }
  .invite-step {
    padding-top: 0;
    margin-bottom: 0; }
  .invite-steps::before {
    content: "";
    position: absolute;
    top: 18px;
    width: 100%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #dfe1e6; }
  .invite-steps.ant-steps-horizontal.ant-steps-label-horizontal,
  .invite-steps.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item {
    display: flex; }
  .invite-steps.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    margin-right: 12px; } }

.ebs-header {
  font-size: 14px; }
  .ebs-header.ant-layout-header {
    height: 58px;
    line-height: 58px;
    padding: 0 10px;
    background: #fff;
    border-bottom: 1px solid #dfe1e6; }
  .ebs-header__row {
    height: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between; }
  .ebs-header__col {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 10px 20px;
    flex: 1; }
  .ebs-header__logo {
    flex: 0 0 auto;
    width: auto;
    height: auto;
    margin-right: 280px;
    max-width: 200px;
    max-height: 36px;
    overflow: hidden; }
    .ebs-header__logo img {
      max-height: 36px; }
    .ebs-header__logo-wrapper {
      display: flex;
      align-items: center;
      max-width: 300px;
      min-width: 200px;
      width: auto;
      margin-left: -1px;
      padding-right: 10px;
      cursor: pointer; }
    .ebs-header__logo--title {
      margin-right: 17px;
      max-width: 115px; }
  .ebs-header__title {
    color: #333;
    font-weight: 600;
    padding-right: 15px;
    margin-left: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block; }
  .ebs-header__content {
    border-left: 1px solid #dfe1e6; }
  .ebs-header__profile {
    flex: 0 0 25%;
    border-left: 1px solid #dfe1e6; }
    .ebs-header__profile:empty {
      display: none; }

.ebs-layout.ant-layout {
  background-color: #fff;
  min-height: 100vh; }

.ebs-layout.ant-layout.ant-layout-has-sider {
  min-height: 100%; }

*:focus:not(.focus-visible) {
  outline: none; }

.sort-button {
  height: 32px;
  width: fit-content;
  color: #1f325f;
  font-size: 13px;
  font-weight: 500;
  line-height: 32px;
  border: 1px solid #dfe1e6;
  border-radius: 4px;
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.16);
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  overflow: hidden; }

.sort-button:not(:last-child) {
  margin-right: 15px; }

.sort-button *::selection {
  background-color: transparent;
  color: inherit; }

.sort-button:hover {
  color: #42526e;
  border: 1px solid #dfe1e6;
  box-shadow: 0 12px 27px 1px rgba(111, 116, 126, 0.22); }

.sort-button:not(.has-direction):hover {
  background-color: #f1f5f7;
  cursor: pointer; }

.sort-button__title {
  padding: 8px 15px; }

.sort-button__icon {
  border-right: 1px solid #dfe1e6;
  z-index: 2; }

.sort-button__icon svg {
  transform: translateY(2px); }

.sort-button__icon,
.has-direction .sort-button__title {
  position: relative;
  padding: 8px 10px;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.3s linear; }

.sort-button__icon:hover,
.has-direction .sort-button__title:hover {
  background-color: #f1f5f7; }

.has-direction .sort-button__title {
  padding-left: 46px;
  margin-left: -38px; }

/* Sort dropdown */
.sort-button__dropdown {
  padding: 0;
  background-color: #f7fafc;
  border: 1px solid #dfe1e6;
  box-shadow: 0 12px 27px 3px rgba(25, 39, 52, 0.17);
  overflow: hidden auto;
  width: auto;
  min-width: 160px;
  max-width: 200px;
  max-height: 450px; }

.sort-button__dropdown__header {
  pointer-events: none; }

.sort-button__dropdown__item {
  padding: 7px 14px;
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  color: #1f325f;
  text-overflow: ellipsis;
  overflow: hidden; }

.sort-button__dropdown__item:first-child {
  color: #42526e;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase; }

.sort-button__dropdown__item:not(:last-child) {
  border-bottom: 1px solid #dfe1e6; }

.sort-button__dropdown__item.ant-dropdown-menu-item-selected:hover,
.sort-button__dropdown__item:not(:first-child):hover {
  background-color: #b4d4fd;
  color: #3572b0; }

.sort-button__dropdown__item.ant-dropdown-menu-item-selected,
.sort-button__dropdown__item--active {
  color: #0052cc;
  background-color: #fff; }

.page-title.ant-typography {
  font-size: 16px;
  color: #333;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0;
  white-space: nowrap; }

.common, .ebs-platform-sidebar, .ebs-platform-sidebar__bottom {
  background-color: #fff;
  border-right: 1px solid #dfe1e6; }

.ebs-platform-sidebar {
  font-family: "Montserrat", Courier, monospace;
  position: sticky;
  top: 0;
  overflow: hidden;
  max-height: max-content !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .ebs-platform-sidebar--collapsed .ebs-platform-sidebar__collapse {
    margin-right: -1px; }
    .ebs-platform-sidebar--collapsed .ebs-platform-sidebar__collapse svg {
      transform: rotateY(0deg); }
  .ebs-platform-sidebar--collapsed .ebs-platform-sidebar__collapse__title {
    max-width: 0;
    opacity: 0;
    margin: 0; }
  .ebs-platform-sidebar__collapse {
    padding: 12px 23px;
    margin-bottom: 20px;
    line-height: 12px;
    display: flex;
    align-content: center;
    transition: all 200ms ease 0ms; }
    .ebs-platform-sidebar__collapse:hover {
      background-color: #f1f5f7;
      cursor: pointer; }
      .ebs-platform-sidebar__collapse:hover .ebs-platform-sidebar__collapse__title {
        color: #42526e; }
      .ebs-platform-sidebar__collapse:hover .ebs-platform-sidebar__collapse__icon {
        color: #3572b0; }
    .ebs-platform-sidebar__collapse__title {
      user-select: none;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      color: #1f325f;
      margin-left: 18px;
      transition: all 200ms ease 0ms;
      display: inline-block;
      white-space: nowrap; }
    .ebs-platform-sidebar__collapse__icon {
      color: #1f325f;
      font-size: 18px;
      transition: all 200ms ease 0ms; }
      .ebs-platform-sidebar__collapse__icon svg {
        transform: rotateY(-180deg); }
  .ebs-platform-sidebar .ant-layout-sider-children {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: inherit; }
  .ebs-platform-sidebar__menu {
    border-right: 0; }
    .ebs-platform-sidebar__menu.ant-menu-inline-collapsed {
      width: 63px; }
      .ebs-platform-sidebar__menu.ant-menu-inline-collapsed > .ebs-platform-sidebar__menu__item {
        padding: 0 23px !important; }
        .ebs-platform-sidebar__menu.ant-menu-inline-collapsed > .ebs-platform-sidebar__menu__item .ebs-platform-sidebar__menu__icon + .ebs-platform-sidebar__menu__title,
        .ebs-platform-sidebar__menu.ant-menu-inline-collapsed > .ebs-platform-sidebar__menu__item .ebs-platform-sidebar__menu__title + .ebs-platform-sidebar__menu__badge {
          display: inline-block;
          max-width: 0;
          opacity: 0;
          margin-left: 0; }
    .ebs-platform-sidebar__menu.ant-menu .ebs-platform-sidebar__menu__item {
      margin: 0 -1px 0 0; }
    .ebs-platform-sidebar__menu__item.ant-menu-item {
      display: flex;
      align-items: center;
      padding-right: 23px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }
      .ebs-platform-sidebar__menu__item.ant-menu-item:hover {
        background-color: #f1f5f7 !important; }
        .ebs-platform-sidebar__menu__item.ant-menu-item:hover .ebs-platform-sidebar__menu__icon {
          color: #3572b0; }
        .ebs-platform-sidebar__menu__item.ant-menu-item:hover .ebs-platform-sidebar__menu__title {
          color: #42526e; }
      .ebs-platform-sidebar__menu__item.ant-menu-item.ant-menu-item-selected {
        background-color: #0052cc !important; }
        .ebs-platform-sidebar__menu__item.ant-menu-item.ant-menu-item-selected .ebs-platform-sidebar__menu__icon,
        .ebs-platform-sidebar__menu__item.ant-menu-item.ant-menu-item-selected .ebs-platform-sidebar__menu__title {
          color: #fff; }
        .ebs-platform-sidebar__menu__item.ant-menu-item.ant-menu-item-selected::after {
          content: none; }
    .ebs-platform-sidebar__menu__title {
      user-select: none;
      margin-left: 18px;
      font-size: 12px;
      color: #1f325f;
      font-weight: 500;
      transition: all 200ms ease 0ms; }
    .ebs-platform-sidebar__menu__icon {
      display: flex;
      margin: 0;
      font-size: 18px;
      color: #1f325f;
      transition: all 200ms ease 0ms; }
  .ebs-platform-sidebar__bottom {
    bottom: 20px;
    position: fixed;
    width: inherit; }
  .ebs-platform-sidebar:not(.ant-layout-sider-collapsed) .ebs-platform-sidebar__item:hover {
    color: #3572b0;
    background: #deebfe; }
  .ebs-platform-sidebar:not(.ant-layout-sider-collapsed) .ant-popover-open.ebs-platform-sidebar__item:not(:hover) {
    color: white;
    background: #0052cc; }
    .ebs-platform-sidebar:not(.ant-layout-sider-collapsed) .ant-popover-open.ebs-platform-sidebar__item:not(:hover) .ebs-platform-sidebar__item-title {
      color: white; }
  .ebs-platform-sidebar__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    overflow: hidden;
    transition: all 200ms ease 0ms; }
    .ebs-platform-sidebar__item-title {
      opacity: 1;
      user-select: none;
      font-size: 12px;
      color: #1f325f;
      font-weight: 500;
      transition: all 200ms ease 0ms; }
      .ebs-platform-sidebar__item-title--hidden {
        display: inline-block;
        opacity: 0;
        margin-left: -12px; }
    .ebs-platform-sidebar__item-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 50px;
      min-height: 40px;
      padding: 5px;
      margin: 0 7px;
      border-radius: 5px;
      color: #0052cc;
      font-size: 28px;
      transition: all 200ms ease 0ms; }
    .ebs-platform-sidebar__item.ant-popover-open .ebs-platform-sidebar__item-logo {
      color: white;
      background: #0052cc; }
    .ebs-platform-sidebar__item:hover .ebs-platform-sidebar__item-title {
      color: #3572b0; }
    .ebs-platform-sidebar__item:hover .ebs-platform-sidebar__item-logo {
      color: #3572b0;
      background: #deebfe; }

.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right .ant-tooltip-arrow {
  position: absolute;
  display: block;
  width: 8px;
  height: 8px;
  pointer-events: none;
  border-left: 1px solid #dfe1e6;
  border-bottom: 1px solid #dfe1e6;
  background-color: #fff;
  z-index: 0;
  left: 4.3px;
  transform: translateY(-50%) rotate(45deg); }
  .ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right .ant-tooltip-arrow::before {
    background-color: #ffffff;
    width: 7px;
    height: 7px;
    box-shadow: none;
    transform: none; }

.ant-menu-inline-collapsed-tooltip .ebs-platform-sidebar__menu__icon {
  display: none; }

.ant-menu-inline-collapsed-tooltip .ebs-platform-sidebar__menu__title {
  margin-left: 0;
  font-size: 11px; }

.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner {
  font-family: "Montserrat", sans-serif;
  align-items: center;
  font-size: 11px;
  color: #1f325f;
  text-align: left;
  border: 1px solid #dfe1e6;
  background-color: #ffffff;
  box-shadow: 0 12px 19px 1px rgba(25, 39, 52, 0.15);
  min-height: 24px; }

.table {
  border: 1px solid #dfe1e6;
  border-radius: 4px; }

.table .ant-table-body {
  overflow-x: auto; }

.table .ant-table-thead > tr > th {
  font-size: 11px;
  line-height: 1.2;
  font-weight: bold;
  text-transform: uppercase;
  color: #1f325f;
  background-color: #fff;
  border-bottom: 1px solid #cbd6e2; }

.table .ant-table-thead > tr > th.ant-table-selection-column,
.table .ant-table-tbody > tr > td.ant-table-selection-column {
  padding-right: 6px;
  padding-left: 20px; }

.table .ant-table-thead > tr > th.ant-table-selection-column + th,
.table .ant-table-tbody > tr > td.ant-table-selection-column + td {
  padding-left: 7px; }

.table .ant-table-thead > tr > th {
  padding: 20px 13px; }

.table .ant-table-tbody > tr > td {
  color: #262626;
  font-size: 12px;
  font-weight: 500;
  padding: 13px;
  border-bottom-color: #dfe4ec; }

.table .ant-table-tbody > tr:hover {
  background-color: #f1f5f7; }

.table .ant-table-tbody > tr:nth-child(odd) {
  background-color: #f7fafc; }

.table .table-cell-actions .ant-dropdown-link {
  float: right;
  color: #1f325f;
  font-weight: 600;
  cursor: pointer; }

/* Table row checkbox */
.table .ant-checkbox-inner {
  background-color: #dfe1e6;
  border-color: #bcc1d3;
  border-radius: 3px; }

.table .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0052cc;
  border-color: #0052cc;
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.12);
  border-radius: 3px; }

.table .ant-checkbox-checked::after {
  border-radius: 3px; }

/* Table Header */
.table .ant-table-title {
  padding: 0;
  top: 0; }

/* Table Footer */
.table .ant-table-footer {
  padding: 20px; }

.table .ant-table-footer:empty {
  display: none; }

.table .table-footer {
  height: 32px; }

/* Sorter icon */
.table .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
  height: 18px; }

.table .ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner-full {
  margin-top: -3px; }

/* Table small */
.table-small .ant-table-thead > tr > th {
  background-color: #f7fafc; }

.table-small .ant-table-thead > tr > th,
.table-small .ant-table-tbody > tr > td {
  padding: 12px 20px; }

.table-small .ant-table-tbody > tr:hover {
  background-color: #fff; }

.table-small .ant-table-tbody > tr:nth-child(odd) {
  background-color: #fff; }

.table-small .ant-table-footer {
  padding: 14px 20px; }

/* Table Pagination */
.table .ant-table {
  z-index: 1; }

.table .table-pagination.ant-table-pagination {
  position: relative;
  z-index: 2;
  margin-top: -52px;
  margin-right: 20px; }

.table-pagination .ant-pagination-prev,
.table-pagination .ant-pagination-next,
.table-pagination .ant-pagination-item,
.table-pagination .table-pagination__btn {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
  height: 28px;
  line-height: 26px;
  color: #1f325f;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

.table-pagination .ant-pagination-item:hover,
.table-pagination .table-pagination__btn:hover {
  color: #3572b0;
  border: 1px solid #dfe1e6;
  background-color: #f1f5f7;
  box-shadow: 0 12px 26.6px 1.4px rgba(111, 116, 126, 0.22); }

.table-pagination .ant-pagination-disabled .table-pagination__btn,
.table-pagination .ant-pagination-disabled .table-pagination__btn:hover {
  color: #8993a4;
  border: 1px solid #dfe1e6;
  background-color: #f4f5f7;
  box-shadow: none; }

._bullet, .ebs-menu-item-bullet::before, .ebs-menu-sub .ebs-menu-item::before {
  content: "";
  position: absolute;
  top: 50%;
  width: 6px;
  height: 6px;
  background-color: #9cc0db;
  border-radius: 3px;
  transform: translateY(-50%);
  transition: all 200ms ease 0ms; }

.ebs-nav {
  min-height: calc(100vh - 58px);
  width: 220px;
  background-color: #f7fafc;
  border-right: 1px solid #dfe1e6;
  padding: 5px; }
  .ebs-nav__action {
    padding: 10px 0 15px; }
    .ebs-nav__action:empty {
      padding: 5px 0; }

.ebs-menu {
  width: 210px;
  margin: 0;
  padding: 0;
  font-size: 13px;
  font-weight: 600;
  color: #1f325f;
  list-style: none;
  outline: none;
  zoom: 1; }
  .ebs-menu-item {
    padding-left: 15px;
    padding-right: 15px;
    position: relative; }
    .ebs-menu-item-active:not(.ebs-menu-item-selected) {
      color: #42526e;
      background-color: #f1f5f7; }
      .ebs-menu-item-active:not(.ebs-menu-item-selected) i,
      .ebs-menu-item-active:not(.ebs-menu-item-selected) svg {
        color: #3572b0; }
      .ebs-menu-item-active:not(.ebs-menu-item-selected) .ebs-menu-item-bullet::before {
        background-color: #3572b0; }
    .ebs-menu-item-selected .ebs-menu-item-bullet::before {
      background-color: #0052cc; }
    .ebs-menu-item-bullet {
      position: relative;
      padding-left: 18px; }
      .ebs-menu-item-bullet::before {
        left: 0; }
    .ebs-menu-item-icon {
      display: flex;
      align-items: center; }
      .ebs-menu-item-icon i,
      .ebs-menu-item-icon svg {
        font-size: 18px;
        transition: all 200ms ease 0ms; }
        .ebs-menu-item-icon i + span,
        .ebs-menu-item-icon svg + span {
          margin-left: 20px; }
    .ebs-menu-item-badge {
      background-color: #1f325f;
      color: #fff;
      font-size: 12px;
      line-height: 19px;
      text-align: center;
      font-weight: 500;
      height: 19px;
      min-width: 19px;
      border-radius: 9px;
      margin-left: 7px;
      padding: 0 5px; }
  .ebs-menu-item, .ebs-menu-submenu-title {
    border-radius: 4px;
    padding: 10px 0;
    line-height: 20px;
    transition: all 200ms ease 0ms; }
    .ebs-menu-item:hover, .ebs-menu-submenu-title:hover {
      cursor: pointer; }
  .ebs-menu-item-selected,
  .ebs-menu-submenu-open .ebs-menu-submenu-title {
    color: #0052cc;
    background-color: #ffffff;
    box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.16); }
  .ebs-menu-submenu-active:not(.ebs-menu-submenu-open) .ebs-menu-submenu-title {
    background-color: #f1f5f7;
    color: #42526e;
    cursor: pointer; }
    .ebs-menu-submenu-active:not(.ebs-menu-submenu-open) .ebs-menu-submenu-title i,
    .ebs-menu-submenu-active:not(.ebs-menu-submenu-open) .ebs-menu-submenu-title svg {
      color: #3572b0; }
  .ebs-menu-sub {
    padding: 0; }
    .ebs-menu-sub:not(.ant-motion-collapse) {
      max-height: 185px;
      overflow: hidden visible; }
    .ebs-menu-sub .ebs-menu-item {
      line-height: 25px;
      padding: 0;
      position: relative;
      background: none;
      box-shadow: none; }
      .ebs-menu-sub .ebs-menu-item::before {
        left: 15px;
        top: 13px; }
      .ebs-menu-sub .ebs-menu-item:first-child {
        margin-top: 10px; }
      .ebs-menu-sub .ebs-menu-item:last-child {
        margin-bottom: 10px; }
      .ebs-menu-sub .ebs-menu-item-active {
        color: #3572b0;
        background-color: #f1f5f7; }
        .ebs-menu-sub .ebs-menu-item-active::before {
          background-color: #3572b0; }
      .ebs-menu-sub .ebs-menu-item-selected {
        color: #0052cc; }
        .ebs-menu-sub .ebs-menu-item-selected::before {
          background-color: #0052cc; }

.ebs-menu-hidden {
  display: none; }

.tag {
  line-height: 0;
  border-radius: 5px;
  box-shadow: 0px 3px 4px 0px rgba(111, 116, 126, 0.12);
  padding: 10px 5px;
  font-size: 11px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  user-select: none;
  transition: 0.25s;
  width: fit-content; }
  .tag:hover {
    opacity: 0.75; }

/* 
 * Container sizes taken from bootstrap,
 * see, https://github.com/twbs/bootstrap/blob/4de4874e722ad934bd3bf21f20a19475096c889a/scss/_variables.scss#L304
 */
.container {
  --size-sm: 540px;
  --size-md: 720px;
  --size-lg: 960px;
  --size-xl: 1140px;
  --size-xxl: 1280px; }

.container {
  width: 100%;
  margin: 0 auto; }

.container--full {
  max-width: 100% !important;
  margin: 0; }

.container--center {
  text-align: center; }

@media (max-width: 575.98px) {
  .container {
    padding: 0 16px; } }

@media (min-width: 576px) and (max-width: 767.98px) {
  .container {
    max-width: var(--size-sm); } }

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .container {
    max-width: var(--size-md); } }

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .container {
    max-width: var(--size-lg); } }

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1599.98px) {
  .container {
    max-width: var(--size-xl); } }

/* Very large devices (1600px and up) */
@media (min-width: 1600px) {
  .container {
    max-width: var(--size-xxl); } }

.ebs-editor {
  width: 100%;
  border: 1px solid #ececec; }
  .ebs-editor__toolbar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px;
    width: 100%;
    border-bottom: 1px solid #ececec; }
    .ebs-editor__toolbar-separator {
      width: 1px;
      margin: 0 5px 0 6px;
      background-color: #cecece;
      height: 20px;
      display: inline-block; }
    .ebs-editor__toolbar-break {
      flex-basis: 100%; }
    .ebs-editor__toolbar-button,
    .ebs-editor__toolbar .mail-editor__button {
      cursor: pointer;
      font-size: 18px;
      background: transparent;
      border: none;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 2px;
      color: rgba(0, 0, 0, 0.3); }
      .ebs-editor__toolbar-button svg,
      .ebs-editor__toolbar .mail-editor__button svg {
        transition: 0.2s; }
        .ebs-editor__toolbar-button svg .activeColor,
        .ebs-editor__toolbar .mail-editor__button svg .activeColor {
          fill: #707070; }
      .ebs-editor__toolbar-button--active,
      .ebs-editor__toolbar .mail-editor__button--active {
        color: #000; }
    .ebs-editor__toolbar button:disabled {
      color: rgba(0, 0, 0, 0.3); }
  .ebs-editor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    visibility: hidden; }
  .ebs-editor .public-DraftEditorPlaceholder-root,
  .ebs-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px; }
  .ebs-editor .public-DraftEditor-content {
    min-height: 100px; }
  .ebs-editor .DraftEditor-root {
    position: relative;
    padding: 0px 5px; }
  .ebs-editor .public-DraftEditorPlaceholder-root {
    position: absolute;
    pointer-events: none;
    color: #707070; }

.column-actions.ant-dropdown-menu {
  width: 140px;
  background-color: #ffffff;
  box-shadow: 0 12px 27.3px 2.7px rgba(25, 39, 52, 0.17); }

.column-actions__header.ant-dropdown-menu-item {
  color: #8993a4;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  pointer-events: none; }

.column-actions__item.ant-dropdown-menu-item {
  font-size: 12px;
  line-height: 20px;
  color: #0052cc; }

.column-actions__item.ant-dropdown-menu-item:hover {
  color: #3572b0;
  background-color: #f1f5f7; }

.column-actions:after {
  content: "";
  position: absolute;
  top: -5px;
  right: 10px;
  width: 10px;
  height: 10px;
  transform: rotateZ(45deg);
  background-color: #fff; }

/* Table Header */
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  border-bottom: 1px solid #dfe1e6; }

.table-header__title {
  font-size: 14px;
  color: #333333;
  font-weight: 600;
  margin: 0; }

.table-header .ant-input-search,
.table-header .ant-btn:not(:last-child) {
  margin-right: 15px; }

.table-header__actions {
  display: flex; }

/* Table Footer */
.table-footer {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.table-footer__records {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  line-height: 1.2; }

.table-footer__nav {
  margin-left: auto;
  margin-right: 0; }

.common-padding, .notifications-drawer header, .notifications-list h1 {
  padding: 2vh 2vw; }

.common-border, .notifications-drawer header, .notifications-list h1, .notification-item {
  border-bottom: 1px solid #dfe1e6 !important; }

.notifications-drawer {
  overflow-x: hidden;
  height: 100%; }
  .notifications-drawer header > * {
    padding: 1vh 0; }
  .notifications-drawer header h1 {
    font-size: 15px; }

.notifications-container {
  background: #f7fafc;
  height: 100%; }
  .notifications-container__close {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px; }

.notifications-select {
  font-size: small;
  font-weight: 600;
  width: 100%;
  text-transform: capitalize; }
  .notifications-select__option {
    text-transform: capitalize;
    font-size: small; }

.notifications-list h1 {
  margin: 0px;
  font-size: 15px;
  color: #0052cc; }

.notifications-list h1.old-notifications {
  color: #42526e; }

.notification-item {
  background: #fff;
  justify-content: left;
  align-items: flex-start;
  padding: 2vh 2vw;
  font-weight: 600; }
  .notification-item__description {
    width: 20vw; }
    .notification-item__description__content {
      font-weight: unset;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
      padding: 0;
      overflow: hidden;
      font-size: small; }
    .notification-item__description__time {
      color: #0052cc; }

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit; }

.load-more-btn {
  text-align: center;
  padding: 12px !important;
  line-height: 32px; }

.chat-drawer .ant-drawer-wrapper-body {
  overflow: hidden; }

.ebs-editor__link-plugin {
  display: flex;
  flex-direction: column; }
  .ebs-editor__link-plugin input {
    margin: 5px 0;
    padding: 4px;
    border: 1px solid #ececec;
    border-radius: 2px; }
  .ebs-editor__link-plugin button {
    color: #333;
    background: #e4e4e4;
    border: none;
    margin-top: 6px;
    border-radius: 3px;
    padding: 5px 0px;
    cursor: pointer;
    opacity: 0.9;
    transition: 0.1s; }
    .ebs-editor__link-plugin button:hover {
      opacity: 1; }

.organization-popover {
  width: 300px; }

.organization-popover .ant-popover-inner-content {
  padding: 10px 0; }

.organization-popover__section-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  font-size: 13px;
  font-weight: 600;
  color: #42526e; }

.organization-popover .organizations__item,
.organization-popover .section-items__item {
  padding: 10px 15px;
  margin: auto;
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  color: #42526e; }

.organization-popover .organizations__item img,
.organization-popover .section-items__item .icon {
  margin-right: 12px; }

.organization-popover .organizations__item:hover,
.organization-popover .section-items__item:hover {
  background-color: #f1f5f7;
  color: #3572b0; }

.organization-popover .section-items__item:hover svg path {
  fill: #3572b0; }

.organization-popover .organizations__item--active::before {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='7px' height='12px'%3E%3Cpath fill-rule='evenodd' fill='rgb(137, 147, 164)' d='M1.097,0.000 L0.011,1.104 L4.061,5.220 L4.061,6.780 L0.011,10.897 L1.097,12.000 L7.000,6.000 L1.097,0.000 Z'/%3E%3C/svg%3E");
  position: absolute;
  right: 15px; }

.organization-popover .organizations__item--active {
  color: #0052cc; }

.organization-popover .organizations__item:not(:last-child)::after {
  background-color: #dfe1e6;
  bottom: 0;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 0);
  width: calc(100% - 30px); }

.organization-popover__section:not(:last-child) {
  border-bottom: 1px solid #dfe1e6; }

.organization-popover__organizations {
  max-height: 350px;
  overflow-y: auto; }

.applications-popover .ant-popover-inner-content {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center; }

.applications-popover__applications {
  display: flex;
  flex-wrap: wrap;
  max-width: 640px;
  margin: 5px 0; }

.applications-popover .footer_link {
  margin: 10px 0;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  line-height: 1; }

.applications-popover .footer_link {
  color: #1f325f; }

.applications-popover .footer_link:hover {
  color: #3572b0; }

.applications-popover .footer_link svg {
  margin-left: 12px;
  transition: cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.15s;
  transform: translate(-2px, 0); }

.applications-popover .footer_link svg path {
  transition: cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.15s;
  fill: #1f325f; }

.applications-popover .footer_link:hover svg {
  transform: translate(1px, 0);
  fill: #3572b0; }

.applications-popover .footer_link:hover svg path {
  fill: #3572b0; }

.ebs-tooltip.rc-tooltip {
  background-color: transparent;
  opacity: 1;
  width: 100%;
  max-width: 320px;
  min-width: 200px; }

.ebs-tooltip[class*="rc-tooltip-placement"] .rc-tooltip-arrow {
  border-bottom-color: #ececec;
  border-top-color: #ececec; }

.ebs-tooltip .rc-tooltip-inner {
  background-color: #fff;
  border-color: #ececec;
  box-shadow: 0 0 5px 0 rgba(236, 236, 236, 0.9); }

/* Application */
.application__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  padding: 15px;
  transition: background-color 0.2s linear;
  text-align: center; }
  .application__item__icon, .application__item__avatar {
    min-height: 62px; }
  .application__item__avatar {
    display: flex;
    align-items: center;
    justify-content: center; }

.application__item:hover {
  cursor: pointer; }

.application__item.application__small {
  width: 160px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center; }

.application__item.application__small:hover {
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.2); }

/* Icon */
.application__item__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  transition: all 0.2s linear;
  color: #fff;
  margin: 0 auto 20px; }

.application__small .application__item__icon {
  margin-bottom: 15px; }

/* Title */
.application__item__title {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4;
  text-align: center;
  margin-bottom: 0.5em;
  margin-right: 0; }

/* Description */
.application__item__description {
  font-size: 11px;
  font-weight: 400;
  margin: 0; }

.application__item__title,
.application__item__description {
  color: #333333;
  transition: color 0.2s linear; }

/* Status */
.application__item__status {
  position: absolute;
  top: 10px;
  right: 20px; }

/* Default hover styles */
.application__item:hover .application__item__title,
.application__item:hover .application__item__description {
  color: #fff; }

.application__item:hover .application__item__icon {
  background-color: #fff; }

/* Applications styles */
.applications {
  --default-color: #0052cc;
  --cms-color: #00749c;
  --notifications-color: #fab63b;
  --chat-color: #3dbaf1;
  --tasks-color: #b7d258;
  --storage-color: #455aea;
  --documentation-color: #f1396f;
  --management-color: #9dc0da;
  --calendar-color: #27acc5;
  --faas-color: #59ba7c;
  --server-color: #a4a2f7;
  --monitoring-color: #b7d258;
  --analytics-color: #5dd9a4;
  --bpm-color: #f44646; }

/* default and auth */
.application__item--auth .application__item__icon,
.application__item--default .application__item__icon {
  background-color: var(--default-color); }

.application__item--auth:hover .application__item__icon,
.application__item--default:hover .application__item__icon {
  color: var(--default-color); }

.application__item--auth:hover,
.application__item--default:hover {
  border-color: var(--default-color);
  background-color: var(--default-color);
  box-shadow: 0 15px 29px 10px rgba(0, 82, 204, 0.22), 0 2px 20px 0 rgba(0, 82, 204, 0.15); }

/* CMS */
.application__item--cms .application__item__icon {
  background-color: var(--cms-color); }

.application__item--cms:hover .application__item__icon {
  color: var(--cms-color); }

.application__item--cms:hover {
  border-color: var(--cms-color);
  background-color: var(--cms-color);
  box-shadow: 0 15px 29px 10px rgba(0, 116, 156, 0.22), 0 2px 20px 0 rgba(0, 116, 156, 0.15); }

/* Chat */
.application__item--chat .application__item__icon {
  background-color: var(--chat-color); }

.application__item--chat:hover .application__item__icon {
  color: var(--chat-color); }

.application__item--chat:hover {
  border-color: var(--chat-color);
  background-color: var(--chat-color);
  box-shadow: 0 15px 29px 10px rgba(61, 186, 241, 0.22), 0 2px 20px 0 rgba(61, 186, 241, 0.15); }

/* Calendar */
.application__item--calendar .application__item__icon {
  background-color: var(--calendar-color); }

.application__item--calendar:hover .application__item__icon {
  color: var(--calendar-color); }

.application__item--calendar:hover {
  border-color: var(--calendar-color);
  background-color: var(--calendar-color);
  box-shadow: 0 15px 29px 10px rgba(39, 172, 197, 0.22), 0 2px 20px 0 rgba(39, 172, 197, 0.15); }

/* Notifications */
.application__item--notifications .application__item__icon {
  background-color: var(--notifications-color); }

.application__item--notifications:hover .application__item__icon {
  color: var(--notifications-color); }

.application__item--notifications:hover {
  border-color: var(--notifications-color);
  background-color: var(--notifications-color);
  box-shadow: 0 15px 29px 10px rgba(250, 182, 59, 0.22), 0 2px 20px 0 rgba(250, 182, 59, 0.15); }

/* Storage */
.application__item--storage .application__item__icon {
  background-color: var(--storage-color); }

.application__item--storage:hover .application__item__icon {
  color: var(--storage-color); }

.application__item--storage:hover {
  border-color: var(--storage-color);
  background-color: var(--storage-color);
  box-shadow: 0 15px 29px 10px rgba(69, 90, 234, 0.22), 0 2px 20px 0 rgba(69, 90, 234, 0.15); }

/* Management */
.application__item--management .application__item__icon {
  background-color: var(--management-color); }

.application__item--management:hover .application__item__icon {
  color: var(--management-color); }

.application__item--management:hover {
  border-color: var(--management-color);
  background-color: var(--management-color);
  box-shadow: 0 15px 29px 10px rgba(157, 192, 218, 0.22), 0 2px 20px 0 rgba(157, 192, 218, 0.15); }

/* Tasks */
.application__item--tasks .application__item__icon {
  background-color: var(--tasks-color); }

.application__item--tasks:hover .application__item__icon {
  color: var(--tasks-color); }

.application__item--tasks:hover {
  border-color: var(--tasks-color);
  background-color: var(--tasks-color);
  box-shadow: 0 15px 29px 10px rgba(183, 210, 88, 0.22), 0 2px 20px 0 rgba(183, 210, 88, 0.15); }

/* FAAS */
.application__item--faas .application__item__icon {
  background-color: var(--faas-color); }

.application__item--faas:hover .application__item__icon {
  color: var(--faas-color); }

.application__item--faas:hover {
  border-color: var(--faas-color);
  background-color: var(--faas-color);
  box-shadow: 0 15px 29px 10px rgba(89, 186, 124, 0.22), 0 2px 20px 0 rgba(89, 186, 124, 0.15); }

/* Server */
.application__item--server .application__item__icon {
  background-color: var(--server-color); }

.application__item--server:hover .application__item__icon {
  color: var(--server-color); }

.application__item--server:hover {
  border-color: var(--server-color);
  background-color: var(--server-color);
  box-shadow: 0 15px 29px 10px rgba(164, 162, 247, 0.22), 0 2px 20px 0 rgba(164, 162, 247, 0.15); }

/* Monitoring */
.application__item--monitoring .application__item__icon {
  background-color: var(--monitoring-color); }

.application__item--monitoring:hover .application__item__icon {
  color: var(--monitoring-color); }

.application__item--monitoring:hover {
  border-color: var(--monitoring-color);
  background-color: var(--monitoring-color);
  box-shadow: 0 15px 29px 10px rgba(183, 210, 88, 0.22), 0 2px 20px 0 rgba(183, 210, 88, 0.15); }

/* Analytics */
.application__item--analytics .application__item__icon {
  background-color: var(--analytics-color); }

.application__item--analytics:hover .application__item__icon {
  color: var(--analytics-color); }

.application__item--analytics:hover {
  border-color: var(--analytics-color);
  background-color: var(--analytics-color);
  box-shadow: 0 15px 29px 10px rgba(93, 217, 164, 0.22), 0 2px 20px 0 rgba(93, 217, 164, 0.15); }

/* BPM */
.application__item--bpm .application__item__icon {
  background-color: var(--bpm-color); }

.application__item--bpm:hover .application__item__icon {
  color: var(--bpm-color); }

.application__item--bpm:hover {
  border-color: var(--bpm-color);
  background-color: var(--bpm-color);
  box-shadow: 0 15px 29px 10px rgba(244, 70, 70, 0.22), 0 2px 20px 0 rgba(244, 70, 70, 0.15); }

.organizations__item img {
  max-width: 24px;
  max-height: 24px;
  height: auto;
  width: auto; }

.organizations__item img[alt] {
  font-size: 0; }
