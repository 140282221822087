/* Common Styles */
/* Search input */
.search-input {
    height: 34px;
    width: 100%;
    margin-bottom: 15px;
}
.search-input .ant-input-search-icon {
    color: #42526e;
    font-size: 17px;
    transition: color 0.2s linear;
    transform: rotateY(180deg);
}
.search-input .ant-input-search-icon:hover {
    color: #3572b0;
}
.search-input .ant-input {
    color: #333;
    font-size: 13px;
    line-height: 32px;
    font-weight: 500;
    border: 1px solid #cbd6e2;
    background-color: #f5f8fa;
}
.search-input .ant-input::placeholder {
    color: #42526e;
}
.search-input .ant-input:active,
.search-input .ant-input:focus {
    background-color: #fff;
}
.action-buttons button:not(:last-child) {
  margin-right: 15px;
}
.sidebar-menu.ant-menu {
  background-color: rgb(247, 250, 252);
}

.sidebar-menu a {
  font-size: 13px;
  color: #1f325f;
  font-weight: bold;
}

.sidebar-menu .nav-menu__sub.ant-menu-submenu-open .ant-menu-submenu-title {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 3.96px 0.04px rgba(111, 116, 126, 0.16);
  height: 40px;
}
.actions-bar {
  width: 64px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dfe1e6;
}

.actions-bar__section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.actions-bar__section .action {
  width: 64px;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.actions-bar__section .action:hover {
  border-radius: 4px;
  background-color: rgb(222, 235, 254);
}

.actions-bar__section .action .ant-skeleton-header {
  padding-left: 13px;
  padding-right: 13px;
}
.actions-bar__section .action img {
  max-width: 38px;
}

.actions-bar__section.platform {
  justify-content: flex-end;
  padding-bottom: 16px;
}
.icon-button {
  display: flex;
  align-items: center;
}
.icon-button svg {
  margin-right: 10px;
}
.page-title.ant-typography {
  font-size: 16px;
  color: #333;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 0;
  white-space: nowrap;
}
.page-header {
  margin-bottom: 16px;
}
.page-header__title.ant-typography {
  font-size: 16px;
  font-weight: 600;
  margin-right: 10px;
  margin-bottom: 15px;
}
.page-header__buttons {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}
.page-header__buttons .ant-btn {
  font-size: 13px;
  line-height: 32px; /* button height - border */
  height: 34px;
}
.page-header__buttons .ant-radio-group,
.page-header__buttons .ant-btn:not(:last-child) {
  margin-right: 15px;
}
.page-header__buttons .ant-btn:first-child {
  border-color: #dfe1e6;
}
.page-header__buttons .ant-btn-primary:hover {
  background-color: #3572b0;
  border-color: #3572b0;
  box-shadow: 0 12px 27px 1px rgba(111, 116, 126, 0.22);
}

@media (min-width: 576px) {
  .page-header__search-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .page-header__search-box .search-input {
    margin-bottom: 0;
  }
  .page-header__buttons {
    align-items: center;
  }
}

@media (min-width: 768px) {
  .page-header__buttons,
  .page-header__search-box {
    justify-content: flex-start;
  }
}

@media (min-width: 992px) {
  .page-header__title.ant-typography {
    margin-bottom: 0;
  }
  .page-header__buttons {
    justify-content: flex-end;
  }
}
.radio-buttons {
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.12);
  margin-bottom: 15px;
  border-radius: 4px;
}
.radio-buttons .ant-radio-button-wrapper {
  font-size: 13px;
  color: #1f325f;
  font-weight: 500;
  height: 34px;
  line-height: 32px;
  border-color: #dfe1e6;
}

@media (min-width: 768px) {
  .radio-buttons {
    margin-bottom: 0;
  }
}
.form-builder {
  height: 100%;
}
.form-builder__container {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.form-builder__content {
  width: 100%;
  background-color: #fff;
}
.table {
  border: 1px solid #dfe1e6;
  border-radius: 4px;
}
.table .ant-table-body {
  overflow-x: auto;
}
.table .ant-table-thead > tr > th {
  font-size: 11px;
  line-height: 1.2;
  font-weight: bold;
  text-transform: uppercase;
  color: #1f325f;
  background-color: #fff;
  border-bottom: 1px solid #cbd6e2;
}
.table .ant-table-thead > tr > th.ant-table-selection-column,
.table .ant-table-tbody > tr > td.ant-table-selection-column {
  padding-right: 6px;
  padding-left: 20px;
}
.table .ant-table-thead > tr > th.ant-table-selection-column + th,
.table .ant-table-tbody > tr > td.ant-table-selection-column + td {
  padding-left: 7px;
}
.table .ant-table-thead > tr > th {
  padding: 20px 13px;
}
.table .ant-table-tbody > tr > td {
  color: #262626;
  font-size: 12px;
  font-weight: 500;
  padding: 13px;
  border-bottom-color: #dfe4ec;
}
.table .ant-table-tbody > tr:hover {
  background-color: #f1f5f7;
}
.table .ant-table-tbody > tr:nth-child(odd) {
  background-color: #f7fafc;
}
.table .table-cell-actions a:not(.action-link) {
  float: right;
  color: #1f325f;
}
.table .table-cell-actions .action-link {
  font-weight: 600;
  color: #1f325f;
  cursor: pointer;
}

/* Table row checkbox */
.table .ant-checkbox-inner {
  background-color: #dfe1e6;
  border-color: #bcc1d3;
  border-radius: 3px;
}
.table .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0052cc;
  border-color: #0052cc;
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.12);
  border-radius: 3px;
}
.table .ant-checkbox-checked::after {
  border-radius: 3px;
}

/* Table Header */
.table .ant-table-title {
  padding: 0;
  top: 0;
}

/* Table Footer */
.table .ant-table-footer {
  padding: 20px;
}
.table .ant-table-footer:empty {
  display: none;
}
.table .table-footer {
  height: 32px;
}

/* Sorter icon */
.table
  .ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner {
  height: 18px;
}
.table
  .ant-table-thead
  > tr
  > th
  .ant-table-column-sorter
  .ant-table-column-sorter-inner-full {
  margin-top: -3px;
}

/* Table small */
.table-small .ant-table-thead > tr > th {
  background-color: #f7fafc;
}
.table-small .ant-table-thead > tr > th,
.table-small .ant-table-tbody > tr > td {
  padding: 12px 20px;
}
.table-small .ant-table-tbody > tr:hover {
  background-color: #fff;
}
.table-small .ant-table-tbody > tr:nth-child(odd) {
  background-color: #fff;
}
.table-small .ant-table-footer {
  padding: 14px 20px;
}

/* Table Pagination */
.table .ant-table {
  z-index: 1;
}
.table .table-pagination.ant-table-pagination {
  position: relative;
  z-index: 2;
  margin-top: -52px;
  margin-right: 20px;
}
.table-pagination .ant-pagination-prev,
.table-pagination .ant-pagination-next,
.table-pagination .ant-pagination-item,
.table-pagination .table-pagination__btn {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 13px;
  height: 28px;
  line-height: 26px;
  color: #1f325f;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.table-pagination .ant-pagination-item:hover,
.table-pagination .table-pagination__btn:hover {
  color: #3572b0;
  border: 1px solid #dfe1e6;
  background-color: #f1f5f7;
  box-shadow: 0 12px 26.6px 1.4px rgba(111, 116, 126, 0.22);
}
.table-pagination .ant-pagination-disabled .table-pagination__btn,
.table-pagination .ant-pagination-disabled .table-pagination__btn:hover {
  color: #8993a4;
  border: 1px solid #dfe1e6;
  background-color: #f4f5f7;
  box-shadow: none;
}
.sort-button {
  color: #1f325f;
  font-size: 13px;
  font-weight: 500;
  line-height: 32px;
  border: 1px solid #dfe1e6;
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.16);
  border-radius: 4px;
  transition: all 0.3s linear;
  overflow: hidden;
}
.sort-button:not(:last-child) {
  margin-right: 15px;
}
.sort-button *::selection {
  background-color: transparent;
  color: inherit;
}
.sort-button:hover {
  color: #42526e;
  border: 1px solid #dfe1e6;
  box-shadow: 0 12px 27px 1px rgba(111, 116, 126, 0.22);
}
.sort-button:not(.has-direction):hover {
  background-color: #f1f5f7;
  cursor: pointer;
}

.sort-button__title {
  padding: 8px 15px;
}

.sort-button__icon {
  border-right: 1px solid #dfe1e6;
  z-index: 2;
}
.sort-button__icon svg {
  transform: translateY(2px);
}

.sort-button__icon,
.has-direction .sort-button__title {
  position: relative;
  padding: 8px 10px;
  cursor: pointer;
  background-color: #fff;
  transition: background-color 0.3s linear;
}
.sort-button__icon:hover,
.has-direction .sort-button__title:hover {
  background-color: #f1f5f7;
}

.has-direction .sort-button__title {
  padding-left: 46px;
  margin-left: -38px;
}

/* Sort dropdown */
.sort-button__dropdown {
  padding: 0;
  background-color: #f7fafc;
  border: 1px solid #dfe1e6;
  box-shadow: 0 12px 27px 3px rgba(25, 39, 52, 0.17);
  overflow: hidden auto;
  width: auto;
  min-width: 160px;
  max-width: 200px;
  max-height: 450px;
}
.sort-button__dropdown__header {
  pointer-events: none;
}
.sort-button__dropdown__item {
  padding: 7px 14px;
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
  color: #1f325f;
  text-overflow: ellipsis;
  overflow: hidden;
}
.sort-button__dropdown__item:first-child {
  color: #42526e;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
}
.sort-button__dropdown__item:not(:last-child) {
  border-bottom: 1px solid #dfe1e6;
}
.sort-button__dropdown__item.ant-dropdown-menu-item-selected:hover,
.sort-button__dropdown__item:not(:first-child):hover {
  background-color: #b4d4fd;
  color: #3572b0;
}
.sort-button__dropdown__item.ant-dropdown-menu-item-selected,
.sort-button__dropdown__item--active {
  color: #0052cc;
  background-color: #fff;
}
.form-wrapper .ant-input {
    font-weight: 500;
}
.form-wrapper .ant-input::placeholder,
.form-wrapper .ant-form-item-label > label {
    color: #42526e;
    font-size: 12px;
    font-weight: 500;
}
.form-wrapper .ant-input:active,
.form-wrapper .ant-input:focus {
    border: 1px solid #b4d4fd;
    box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.16);
}

.form-wrapper .ant-form-item {
    margin-bottom: 12px;
}

.form-wrapper__content {
    border: 1px dashed #cbd6e2;
    border-radius: 12px;
    background-color: #f7fafc;
    padding: 20px 30px;
}
.form-wrapper__header {
    margin-bottom: 20px;
}
.form-wrapper__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}

.form-wrapper__buttons .ant-btn {
    line-height: 30px;
}
.form-wrapper__buttons .ant-btn:only-child {
    margin-left: auto;
    margin-right: 0;
}

@media (min-width: 576px) {
    .form-wrapper.ant-form-vertical .ant-form-item-label {
        padding: 0;
        line-height: 32px;
    }
}
.user-popover {
    width: 300px;
  }

  .user-popover .ant-popover-inner-content {
    padding: 10px 0;
  }

  .user-popover__section-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    font-size: 13px;
    font-weight: 600;
    color: rgb(66, 82, 110);
  }

  .user-popover__section-header__link {
    font-size: 13px;
    color: rgb(0, 82, 204);
  }

  .user-popover__section-header__link:hover {
    font-size: 13px;
    color: rgb(53, 114, 176);
  }

  .user-popover .applications__item,
  .user-popover .section-items__item {
    padding: 10px 15px;
    margin: auto;
    display: flex;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    position: relative;
    color: rgb(66, 82, 110);
  }

  .user-popover .applications__item img,
  .user-popover .section-items__item .icon {
    margin-right: 12px;
  }

  .user-popover .applications__item:hover,
  .user-popover .section-items__item:hover {
    background-color: rgb(241, 245, 247);
    color: rgb(53, 114, 176);
  }

  .user-popover .section-items__item:hover svg path {
    fill: rgb(53, 114, 176);
  }

  .user-popover .applications__item--active::before {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='7px' height='12px'%3E%3Cpath fill-rule='evenodd' fill='rgb(137, 147, 164)' d='M1.097,0.000 L0.011,1.104 L4.061,5.220 L4.061,6.780 L0.011,10.897 L1.097,12.000 L7.000,6.000 L1.097,0.000 Z'/%3E%3C/svg%3E");
    position: absolute;
    right: 15px;
  }

  .user-popover .applications__item--active {
    color: rgb(0, 82, 204);
  }

  .user-popover .applications__item:not(:last-child)::after {
    background-color: rgb(223, 225, 230);
    bottom: 0;
    content: "";
    display: block;
    height: 1px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0);
    width: calc(100% - 30px);
  }

  .user-popover__section:not(:last-child) {
    border-bottom: 1px solid rgb(223, 225, 230);
  }

.user-popover__applications {
  max-height: 350px;
  height: auto;
  overflow-y: auto;
}
.column-actions.ant-dropdown-menu {
  width: 140px;
  background-color: #ffffff;
  box-shadow: 0 12px 27.3px 2.7px rgba(25, 39, 52, 0.17);
}

.column-actions__header.ant-dropdown-menu-item {
  color: #8993a4;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  pointer-events: none;
}

.column-actions__item.ant-dropdown-menu-item {
  font-size: 12px;
  line-height: 20px;
  color: #0052cc;
}

.column-actions__item.ant-dropdown-menu-item:hover {
  color: #3572b0;
  background-color: #f1f5f7;
}

.column-actions:after {
  content: "";
  position: absolute;
  top: -5px;
  right: 10px;
  width: 10px;
  height: 10px;
  transform: rotateZ(45deg);
  background-color: #fff;
}
/* Table Footer */
.table-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table-footer__records {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  line-height: 1.2;
}
.table-footer__nav {
  margin-left: auto;
  margin-right: 0;
}
.form-generator {
  max-width: 700px;
  padding: 25px;
  margin: 0 auto;
}
.form-generator .form-field__item.ant-form-item {
  margin-bottom: 12px;
}
/* Table Header */
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  border-bottom: 1px solid #dfe1e6;
}
.table-header__title {
  font-size: 14px;
  color: #333333;
  font-weight: 600;
  margin: 0;
}
.table-header .ant-input-search,
.table-header .ant-btn:not(:last-child) {
  margin-right: 15px;
}

.table-header__actions {
  display: flex;
}
.layout-header.ant-layout-header {
  height: auto;
  line-height: initial;
  background-color: #fff;
  padding: 0;
  margin-bottom: 30px;
}

.layout-header.ant-layout-header .ant-row-flex {
  padding: 14px 0;
  border-bottom: 1px solid rgba(223, 225, 230, 0.5);
}

.layout-header__profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
}

.layout-header__profile .ant-typography {
  font-size: 13px;
  color: #1f325f;
  font-weight: bold;
  margin-right: 12px;
  line-height: 1;
}
.layout-header .ant-breadcrumb-link {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2;
}

.layout-header .ant-breadcrumb-link a {
  color: #8993a4;
  transition: color 0.2s linear;
}

.layout-header .ant-breadcrumb-link a:hover {
  color: #1f325f;
}

.layout-header .ant-breadcrumb > span:last-child a {
  color: #1f325f;
}

.layout-header .ant-breadcrumb-separator {
  font-family: Consolas, sans-serif;
  font-size: 18px;
  color: #b4d4fd;
  font-weight: bold;
  position: relative;
  top: 1px;
}

@media (min-width: 576px) {
  .layout-header__profile {
    justify-content: flex-end;
    margin-top: 0;
  }
}
.layout__wrapper.ant-layout {
  flex-direction: row;
}
.layout-content__wrapper.ant-layout {
  padding: 0 35px;
  background-color: #fff;
  overflow: hidden !important; /* Overwrite parent styles when layout has sidebar */
}
.layout-content__wrapper .layout-content {
  padding: 0;
}
.form-builder__sidebar--props {
  max-width: 425px;
  padding: 10px 15px;
}
.form-builder__sidebar.form-builder__sidebar--empty {
  background-color: #fff;
  border: 0;
}
.field-properties__title h4 {
  font-size: 14px;
  color: #333333;
  font-weight: 600;
  margin-bottom: 10px;
}
.field-properties__title {
  border-bottom: 1px solid #dfe1e6;
  margin-bottom: 10px;
  margin-top: 5px;
}
.sidebar-content {
  min-height: 100vh;
  height: 100%;
  max-width: 300px;
  width: 100%;
  position: fixed;
  display: flex;
}
.form-builder__sidebar {
  max-width: 295px;
  width: 100%;
  min-height: 100%;
  background-color: #f7fafc;
  border-left: 1px solid #dfe1e6;
  border-right: 1px solid #dfe1e6;
}
.sidebar__collapse.ant-collapse {
  background-color: transparent;
}
.sidebar__collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  font-size: 13px;
  color: #1f325f;
  font-weight: 600;
  padding: 10px 15px;
  transition: all 0.3s linear;
}
.sidebar__collapse.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header:hover {
  background-color: #f1f5f7;
  color: #3572b0;
}
.sidebar__collapse .ant-collapse-content-box {
  padding: 5px 15px 15px;
}
.sidebar__collapse.ant-collapse-borderless > .ant-collapse-item {
  border-color: #dfe1e6;
}
.sidebar__header {
  text-align: left;
  border-bottom: 1px solid #dfe1e6;
  padding: 20px 15px;
}
.sidebar__header__title {
  font-size: 14px;
  color: #333333;
  font-weight: 600;
}
.applications-popover .ant-popover-inner-content {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.applications-popover__applications {
  display: flex;
  flex-wrap: wrap;
  max-width: 640px;
  margin: 5px 0;
}

.applications-popover .footer_link {
  margin: 10px 0;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  line-height: 1;
}

.applications-popover .footer_link {
  color: rgb(31, 50, 95);
}

.applications-popover .footer_link:hover {
  color: rgb(53, 114, 176);
}

.applications-popover .footer_link svg {
  margin-left: 12px;
  transition: cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.15s;
  transform: translate(-2px, 0);
}

.applications-popover .footer_link svg path {
  transition: cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.15s;
  fill: rgb(31, 50, 95);
}

.applications-popover .footer_link:hover svg {
  transform: translate(1px, 0);
  fill: rgb(53, 114, 176);
}

.applications-popover .footer_link:hover svg path {
  fill: rgb(53, 114, 176);
}
.form-media-upload .ant-upload-list-picture .ant-upload-list-item-thumbnail img {
  max-width: 48px;
  max-height: 48px;
  width: auto;
  height: auto;
}
.form-media-upload .ant-upload-list-picture .ant-upload-list-item-thumbnail {
  top: 50%;
  transform: translateY(-50%);
  height: auto;
}
.form-media__modal .ant-form-item-label {
  line-height: 26px;
}
.form-media__modal .ant-form-item {
  margin-bottom: 15px;
}
.form-builder__preview {
  position: relative;
  width: 100%;
  padding: 25px;
}
.preview-drop-zone {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0.5;
  background-color: #b4d4fd;
}
.form-field__item.ant-form-item {
  margin-bottom: 2px;
}
.form-field__item.ant-form-item .ant-form-item-label > label {
  font-size: 12px;
  font-weight: 500;
  color: #42526e;
}
.form-field__item.ant-form-item .ant-form-explain,
.form-field__item .ant-form-extra {
  font-size: 12px;
  font-weight: 500;
}
.ant-form-vertical .form-field__item.ant-form-item .ant-form-item-label {
  padding-bottom: 4px;
}
.form-field__item.ant-form-item .ant-input {
  height: 34px;
  line-height: 34px;
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.16);
  font-size: 13px;
  font-weight: 500;
  color: #333333;
}
.form-field__item.ant-form-item textarea.ant-input {
  height: auto;
  line-height: 1.5;
}
.form-field__item.ant-form-item textarea.ant-input::placeholder,
.form-field__item.ant-form-item .ant-input::placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.form-field__item.ant-form-item .ant-input:active,
.form-field__item.ant-form-item .ant-input:focus {
  border-color: #b4d4fd;
}
.applications__item img {
  max-width: 24px;
  max-height: 24px;
  height: auto;
  width: auto;
}
.layout-footer.ant-layout-footer {
  text-align: center;
  padding: 20px;
  background-color: #fff;
  margin-bottom: 0;
  margin-top: auto;
}

.layout-footer.ant-layout-footer p {
  font-size: 11px;
  color: #42526e;
  margin-bottom: 0;
  font-weight: normal;
}
.form-sidebar__item {
  border: 1px dashed #cbd6e2;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 12px 10px;
  transition: all 0.3s linear;
  cursor: grab;
  background-color: #f7fafc;
}
.form-sidebar__item:hover {
  border-color: #3572b0;
  background-color: #3572b0;
  box-shadow: 0px 15px 29.4px 0.6px rgba(53, 114, 176, 0.22),
    0px 2px 20px 0px rgba(53, 114, 176, 0.15);
}

.form-sidebar__item:hover .form-sidebar__item__title,
.form-sidebar__item:hover .form-sidebar__item__icon svg {
  color: #fff;
}

.form-sidebar__item__icon,
.form-sidebar__item__title {
  text-align: center;
  transition: color 0.3s linear;
}

.form-sidebar__item__title {
  font-size: 13px;
  color: #333333;
  font-weight: 600;
  margin: 0 0 0 12px;
  line-height: 18px;
}
.form-sidebar__item__icon {
  line-height: 9px;
}
.form-sidebar__item__icon svg {
  color: #7a869a;
  transition: color 0.3s linear;
  font-size: 18px;
}
/* Application */
.application__item {
  display: block;
  position: relative;
  border-radius: 12px;
  background-color: #fff;
  padding: 15px;
  transition: background-color 0.2s linear;
  text-align: center;
}
.application__item:hover {
  cursor: pointer;
}

.application__item.application__small {
  width: 160px;
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.application__item.application__small:hover {
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.2);
}

/* Icon */
.application__item__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  transition: all 0.2s linear;
  color: #fff;
  margin: 0 auto 20px;
}

.application__small .application__item__icon {
  margin-bottom: 15px;
}

/* Title */
.application__item__title {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.4;
  text-align: center;
  margin-bottom: 0.5em;
  margin-right: 0;
}

/* Description */
.application__item__description {
  font-size: 11px;
  font-weight: 400;
  margin: 0;
}

.application__item__title,
.application__item__description {
  color: #333333;
  transition: color 0.2s linear;
}

/* Status */
.application__item__status {
  position: absolute;
  top: 10px;
  right: 20px;
}

/* Default hover styles */
.application__item:hover .application__item__title,
.application__item:hover .application__item__description {
  color: #fff;
}
.application__item:hover .application__item__icon {
  background-color: #fff;
}

/* Applications styles */
.applications {
  --default-color: #0052cc;
  --cms-color: #00749c;
  --notifications-color: #fab63b;
  --chat-color: #3dbaf1;
  --tasks-color: #b7d258;
  --storage-color: #455aea;
  --documentation-color: #f1396f;
  --management-color: #9dc0da;
  --calendar-color: #27acc5;
  --faas-color: #59ba7c;
  --server-color: #a4a2f7;
  --monitoring-color: #b7d258;
  --analytics-color: #5dd9a4;
  --bpm-color: #f44646;
}

/* default and auth */
.application__item--auth .application__item__icon,
.application__item--default .application__item__icon {
  background-color: var(--default-color);
}
.application__item--auth:hover .application__item__icon,
.application__item--default:hover .application__item__icon {
  color: var(--default-color);
}
.application__item--auth:hover,
.application__item--default:hover {
  border-color: var(--default-color);
  background-color: var(--default-color);
  box-shadow: 0 15px 29px 10px rgba(0, 82, 204, 0.22),
    0 2px 20px 0 rgba(0, 82, 204, 0.15);
}

/* CMS */
.application__item--cms .application__item__icon {
  background-color: var(--cms-color);
}
.application__item--cms:hover .application__item__icon {
  color: var(--cms-color);
}
.application__item--cms:hover {
  border-color: var(--cms-color);
  background-color: var(--cms-color);
  box-shadow: 0 15px 29px 10px rgba(0, 116, 156, 0.22),
    0 2px 20px 0 rgba(0, 116, 156, 0.15);
}

/* Chat */
.application__item--chat .application__item__icon {
  background-color: var(--chat-color);
}
.application__item--chat:hover .application__item__icon {
  color: var(--chat-color);
}
.application__item--chat:hover {
  border-color: var(--chat-color);
  background-color: var(--chat-color);
  box-shadow: 0 15px 29px 10px rgba(61, 186, 241, 0.22),
    0 2px 20px 0 rgba(61, 186, 241, 0.15);
}

/* Calendar */
.application__item--calendar .application__item__icon {
  background-color: var(--calendar-color);
}
.application__item--calendar:hover .application__item__icon {
  color: var(--calendar-color);
}
.application__item--calendar:hover {
  border-color: var(--calendar-color);
  background-color: var(--calendar-color);
  box-shadow: 0 15px 29px 10px rgba(39, 172, 197, 0.22),
    0 2px 20px 0 rgba(39, 172, 197, 0.15);
}

/* Notifications */
.application__item--notifications .application__item__icon {
  background-color: var(--notifications-color);
}
.application__item--notifications:hover .application__item__icon {
  color: var(--notifications-color);
}
.application__item--notifications:hover {
  border-color: var(--notifications-color);
  background-color: var(--notifications-color);
  box-shadow: 0 15px 29px 10px rgba(250, 182, 59, 0.22),
    0 2px 20px 0 rgba(250, 182, 59, 0.15);
}

/* Storage */
.application__item--storage .application__item__icon {
  background-color: var(--storage-color);
}
.application__item--storage:hover .application__item__icon {
  color: var(--storage-color);
}
.application__item--storage:hover {
  border-color: var(--storage-color);
  background-color: var(--storage-color);
  box-shadow: 0 15px 29px 10px rgba(69, 90, 234, 0.22),
    0 2px 20px 0 rgba(69, 90, 234, 0.15);
}

/* Management */
.application__item--management .application__item__icon {
  background-color: var(--management-color);
}
.application__item--management:hover .application__item__icon {
  color: var(--management-color);
}
.application__item--management:hover {
  border-color: var(--management-color);
  background-color: var(--management-color);
  box-shadow: 0 15px 29px 10px rgba(157, 192, 218, 0.22),
    0 2px 20px 0 rgba(157, 192, 218, 0.15);
}

/* Tasks */
.application__item--tasks .application__item__icon {
  background-color: var(--tasks-color);
}
.application__item--tasks:hover .application__item__icon {
  color: var(--tasks-color);
}
.application__item--tasks:hover {
  border-color: var(--tasks-color);
  background-color: var(--tasks-color);
  box-shadow: 0 15px 29px 10px rgba(183, 210, 88, 0.22),
    0 2px 20px 0 rgba(183, 210, 88, 0.15);
}

/* FAAS */
.application__item--faas .application__item__icon {
  background-color: var(--faas-color);
}
.application__item--faas:hover .application__item__icon {
  color: var(--faas-color);
}
.application__item--faas:hover {
  border-color: var(--faas-color);
  background-color: var(--faas-color);
  box-shadow: 0 15px 29px 10px rgba(89, 186, 124, 0.22),
    0 2px 20px 0 rgba(89, 186, 124, 0.15);
}

/* Server */
.application__item--server .application__item__icon {
  background-color: var(--server-color);
}
.application__item--server:hover .application__item__icon {
  color: var(--server-color);
}
.application__item--server:hover {
  border-color: var(--server-color);
  background-color: var(--server-color);
  box-shadow: 0 15px 29px 10px rgba(164, 162, 247, 0.22),
    0 2px 20px 0 rgba(164, 162, 247, 0.15);
}

/* Monitoring */
.application__item--monitoring .application__item__icon {
  background-color: var(--monitoring-color);
}
.application__item--monitoring:hover .application__item__icon {
  color: var(--monitoring-color);
}
.application__item--monitoring:hover {
  border-color: var(--monitoring-color);
  background-color: var(--monitoring-color);
  box-shadow: 0 15px 29px 10px rgba(183, 210, 88, 0.22),
    0 2px 20px 0 rgba(183, 210, 88, 0.15);
}

/* Analytics */
.application__item--analytics .application__item__icon {
  background-color: var(--analytics-color);
}
.application__item--analytics:hover .application__item__icon {
  color: var(--analytics-color);
}
.application__item--analytics:hover {
  border-color: var(--analytics-color);
  background-color: var(--analytics-color);
  box-shadow: 0 15px 29px 10px rgba(93, 217, 164, 0.22),
    0 2px 20px 0 rgba(93, 217, 164, 0.15);
}

/* BPM */
.application__item--bpm .application__item__icon {
  background-color: var(--bpm-color);
}
.application__item--bpm:hover .application__item__icon {
  color: var(--bpm-color);
}
.application__item--bpm:hover {
  border-color: var(--bpm-color);
  background-color: var(--bpm-color);
  box-shadow: 0 15px 29px 10px rgba(244, 70, 70, 0.22),
    0 2px 20px 0 rgba(244, 70, 70, 0.15);
}
.form-builder__preview-item:not(:last-child) {
  margin-bottom: 15px;
}
.preview-item__container {
  display: flex;
  align-items: center;
  border: 1px dashed #cbd6e2;
  border-radius: 4px;
  padding: 0 10px;
  margin-bottom: 0;
  cursor: pointer;
  overflow: hidden;
  line-height: 1;
}

.preview-item__fieldset {
  padding: 6px 10px 10px 10px;
  border: 1px dotted #b4d4fd;
  border-radius: 7px;
  background-color: #ffffff;
  transition: box-shadow .3s linear;
}
.form-builder__preview-item:hover .preview-item__fieldset {
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.16);
}
.form-builder__preview-item.preview-item--active .preview-item__fieldset {
  background-color: #f7fafc;
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.16);
}

.preview-item__legend {
  width: auto;
  margin-bottom: 0;
  padding: 0 5px;
  font-size: 11px;
  color: #42526e;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.2;
}

.preview-item__icon {
  font-size: 21px;
  line-height: 1;
  margin-right: 15px;
}

.preview-item__text {
  font-size: 13px;
  font-weight: 400;
}

.preview-item__text-label {
  font-weight: bold;
  color: #333;
  margin-right: 10px;
}
.preview-item__text-type {
  font-weight: 400;
  color: #808080;
}
.preview-item__actions {
  margin-left: auto;
  margin-right: -10px;
  background-color: #f7fafc;
  padding: 10px;
  color: #0052cc;
  border-left: 1px dashed #cbd6e2;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-content: center;
  text-align: center;
}
.preview-item__drag {
  margin-right: 10px;
  color: #1f325f;
  font-size: 16px;
  cursor: grab;
}
.preview-item__drag svg {
  margin-top: 1px;
}
.field-item__boolean.ant-form-item {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.field-item__boolean.ant-form-item .ant-form-item-label {
  margin-left: 10px;
}
.ant-form-vertical .field-item__boolean.form-field__item.ant-form-item .ant-form-item-label {
  padding-bottom: 0;
}
.field-item__boolean.form-field__item.ant-form-item .ant-form-item-label > label {
  font-size: 13px;
  font-weight: 600;
  color: #333333;
  cursor: pointer;
}
.field-item__number .ant-input-number {
  width: 100%;
  height: 34px;
  line-height: 34px;
  box-shadow: 0 3px 4px 0 rgba(111, 116, 126, 0.16);
  font-size: 13px;
  font-weight: 500;
  color: #333333;
}
.field-item__image
  .ant-upload-list-picture
  .ant-upload-list-item-thumbnail
  img {
  max-width: 48px;
  max-height: 48px;
  width: auto;
  height: auto;
}
.field-item__image .ant-upload-list-picture .ant-upload-list-item-thumbnail {
  top: 50%;
  transform: translateY(-50%);
  height: auto;
}
.modal-code-editor .ant-modal-body {
  padding: 0;
}
.modal-code-editor .ant-modal-close-x {
  width: 43px;
  height: 43px;
  line-height: 43px;
}
.modal-code-tabs .ant-tabs-bar {
  margin-bottom: 0;
}
.modal-code-logs__container {
  min-height: 450.58px;
  overflow: auto;
  padding: 15px;
}
